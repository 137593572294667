import {DAM_PARENT_FULL_PATH, DAM_PARENT_PATH, DAM_ASSETS_TYPE} from '../constants/damConstants'

interface State {
  damParentPath: any
  damParentFullPath: any
  assetsType: any
}

const initialState: State = {
  damParentPath: '',
  damParentFullPath: '',
  assetsType: [],
}

export const damReducer = (state: State = {...initialState}, action: any): State => {
  switch (action?.type) {
    case DAM_PARENT_PATH:
      return {
        ...state,
        damParentPath: action.payload,
      }

    case DAM_PARENT_FULL_PATH:
      return {
        ...state,
        damParentFullPath: action.payload,
      }

    case DAM_ASSETS_TYPE:
      return {
        ...state,
        assetsType: action.payload,
      }

    default:
      return state
  }
}
