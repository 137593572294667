import {RTL} from '../constants/rtlContasnts'

interface State {
  RTL: boolean
}

const initialState: State = {
  RTL: false,
}

export const rtlReducer = (state: State = {...initialState}, action: any): State => {
  switch (action?.type) {
    case RTL:
      return {
        ...state,
        RTL: !state?.RTL,
      }

    default:
      return state
  }
}
