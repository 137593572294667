import {LoginReducer} from './LoginReducer'
import {rtlReducer} from './rtlReducer'
import {specificSpaceReducer} from './specificSpaceCredsReducer'
import {commonReducer} from './commonReducer'
import {spaceReducer} from './spaceReducer'
import {connectionReducer} from './connectionReducer'
import {shopifyCollectionsReducer} from './shopifyCollectionReducer'
import {damReducer} from './damReducer'

import {combineReducers} from 'redux'
import {generativeStudioReducer} from './generativeStudioReducer'

const rootReducer = combineReducers({
  LoginReducer,
  rtlReducer,
  specificSpaceReducer,
  commonReducer,
  spaceReducer,
  connectionReducer,
  shopifyCollectionsReducer,
  damReducer,
  generativeStudioReducer,
})

export default rootReducer
