import {gql} from '@apollo/client'

export const LOGIN_GQL = gql`
  mutation login($input: LoginInput) {
    login(input: $input) {
      token
      user {
        id
        name
        first_name
        last_name
        email
      }
      permission {
        user {
          email
        }
        permissions {
          id
          name
          permission {
            create
            update
            delete
            view
          }
        }
      }
    }
  }
`

export const LOGINCONNECTION_GQL = gql`
  mutation login($input: LoginInput) {
    login(input: $input) {
      token
      user {
        id
      }
    }
  }
`

export const REGISTER_GQL = gql`
  mutation register($input: RegisterInput) {
    register(input: $input) {
      token
      user {
        id
        email
      }
    }
  }
`
