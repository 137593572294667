import {
  AIRTABLE_CONNECTION_ID,
  GOOGLE_FORM_CONNECTION_ID,
  GOOGLE_SEARCH_CONNECTION_ID,
  GOOGLE_ADD_SITEMAP_URL
} from '../constants/connectionConstants'

interface State {
  airtableConnectionId: any
  googleFormConnectionId: any
  googleSearchConnectionId: any
  googleAddSitemapUrl:any
}

const initialState: State = {
  airtableConnectionId: '',
  googleFormConnectionId: '',
  googleSearchConnectionId: '',
  googleAddSitemapUrl:""
}

export const connectionReducer = (state: State = {...initialState}, action: any): State => {
  switch (action?.type) {
    case AIRTABLE_CONNECTION_ID:
      return {
        ...state,
        airtableConnectionId: action.payload,
      }
    case GOOGLE_FORM_CONNECTION_ID:
      return {
        ...state,
        googleFormConnectionId: action.payload,
      }

    case GOOGLE_SEARCH_CONNECTION_ID:
      return {
        ...state,
        googleSearchConnectionId: action.payload,
      }
      case GOOGLE_ADD_SITEMAP_URL:
      return {
        ...state,
        googleAddSitemapUrl: action.payload,
      }
    default:
      return state
  }
}
