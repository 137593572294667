export const SELECTED_TAB: string = 'SELECTED_TAB'
export const SHOW_COLLABORATION: string = 'SHOW_COLLABORATION'
export const SHOW_PILLAR_PAGES: string = 'SHOW_PILLAR_PAGES'
export const SHOW_ADAPTIVE_PAGES: string = 'SHOW_ADAPTIVE_PAGES'
export const SHOW_SITEMAP: string = 'SHOW_SITEMAP'
export const SHOW_GLOBAL_SECTIONS: string = 'SHOW_GLOBAL_SECTIONS'
export const SHOW_ALL_COMMENTS: string = 'SHOW_ALL_COMMENTS'
export const ALL_ADAPTIVE_PAGES: string = 'ALL_ADAPTIVE_PAGES'
export const ALL_PILLAR_PAGES: string = 'ALL_PILLAR_PAGES'
export const SELECTED_SECTION: string = 'SELECTED_SECTION'
