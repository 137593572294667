import {
  SHOPIFY_PAGES_CONNECTION,
  SHOPIFY_ARTICALS_CONNECTION,
  SHOPIFY_PRODUCTS_CONNECTION,
} from '../constants/shopifyCollectionsConstants'

interface State {
  pagesData: any
  productsData: any
  articalsData: any
}

const initialState: State = {
  pagesData: [],
  productsData: [],
  articalsData: [],
}

export const shopifyCollectionsReducer = (state: State = {...initialState}, action: any): State => {
  switch (action?.type) {
    case SHOPIFY_PAGES_CONNECTION:
      return {
        ...state,
        pagesData: action.payload,
      }

    case SHOPIFY_PRODUCTS_CONNECTION:
      return {
        ...state,
        productsData: action.payload,
      }

    case SHOPIFY_ARTICALS_CONNECTION:
      return {
        ...state,
        articalsData: action.payload,
      }
    default:
      return state
  }
}
