import {
  SPECIFC_SPACE_CREDS,
  SPECIFC_PROJECT_CREDS,
  SPECIFC_CMS_CREDS,
  SET_SHOPIFY_COLLECTION_NAME,
} from '../constants/specificSpaceCredsConstants'

interface State {
  spaceName: any
  spaceID: any
  projectName: any
  projectID: any
  connectionID: any
  appName: any
  shopifyCollectionName: any
  cmsName: any
  cmsID: any
}

const initialState: State = {
  spaceName: null,
  spaceID: null,
  projectName: null,
  projectID: null,
  connectionID: null,
  appName: null,
  shopifyCollectionName: null,
  cmsName: null,
  cmsID: null,
}

export const specificSpaceReducer = (state: State = {...initialState}, action: any): State => {
  switch (action?.type) {
    case SPECIFC_SPACE_CREDS:
      return {
        ...state,
        spaceName: action.name,
        spaceID: action.id,
      }

    case SPECIFC_CMS_CREDS:
      return {
        ...state,
        cmsName: action.name,
        cmsID: action.id,
        connectionID: action.connectionID,
        appName: action.appName,
      }

    case SPECIFC_PROJECT_CREDS:
      return {
        ...state,
        projectName: action.name,
        projectID: action.id,
      }

    case SET_SHOPIFY_COLLECTION_NAME:
      return {
        ...state,
        shopifyCollectionName: action.name,
      }
    default:
      return state
  }
}
