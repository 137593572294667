import {
  LOGIN_SUCCESS,
  LOGIN_RESET,
  LOGIN_TOEKN,
  CONNECTION_TOEKN,
} from '../constants/LoginConstants'

interface State {
  LoginData: any
  token: any
  connectionToken: any
}

const initialState: State = {
  LoginData: null,
  token: null,
  connectionToken: null,
}

export const LoginReducer = (state: State = {...initialState}, action: any): State => {
  switch (action?.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        LoginData: action.payload,
      }
    case LOGIN_TOEKN:
      return {
        ...state,
        token: action.payload,
      }

    case CONNECTION_TOEKN:
      return {
        ...state,
        connectionToken: action.payload,
      }

    case LOGIN_RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
