import {SIDEBAR_MENU_TOGGLE, MENU_ORDER_PAGES} from '../constants/commonConstants'

interface State {
  sideBarToggle: any
  sideBarItemName: any
  menu_order: any
}

const initialState: State = {
  sideBarToggle: true,
  sideBarItemName: '',
  menu_order: '',
}

export const commonReducer = (state: State = {...initialState}, action: any): State => {
  switch (action?.type) {
    case SIDEBAR_MENU_TOGGLE:
      return {
        ...state,
        sideBarToggle: action.toggle,
        sideBarItemName: action.name,
      }

    case MENU_ORDER_PAGES:
      return {
        ...state,
        menu_order: action.payload,
      }
    default:
      return state
  }
}
